import React, { Component } from "react";
import { Row, Col, Card, Button, CardBody, Container } from "reactstrap";
import axiosHttpClient from "../../constants/axios-http-client";
import FormControl from "../../components/Controls/FormControl";
import AppContext from "../../AppContext";
import "../../scss/login.scss";

export const DEFAULT_QUERY_LOGIN = "Login/Connect";
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.data = {
      message1:
        "Deferredcomp.morganstanleyatwork.com is now part of the Morgan Stanley at Work suite of workplace financial solutions.  Please enter your User ID and password to access your benefits.",
    };

    this.UserUpdateData = {
      username: "",
      password: "",
    };

    this.confirmationMessage = "";

    this.hitLogin = false;
    this.form = React.createRef();
    this.buttonRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.displayMessage = this.displayMessage.bind(this);
    this.redirect_ulr = this.redirect_ulr.bind(this);
  }

  static contextType = AppContext;

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.buttonRef.current.focus();
      this.handleSubmit();
    }

    // if (event.key === 'Tab') {
    //   event.preventDefault();
    //   const { target } = event;
    //   const tabIndex = parseInt(target.getAttribute('tabIndex'));

    //   const nextElement = document.querySelector(`[tabIndex="${tabIndex + 1}"]`);

    //   if (nextElement) {
    //     nextElement.focus();
    //   }
    // }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  displayMessage(typeMessage, message) {
    this.form.current.togglemodalOK(typeMessage, message);
  }

  handleSubmit(event) {
    if (this.hitLogin) return;
    this.hitLogin = true;
    if (event !== undefined) {
      event.preventDefault(this.state.values);
    }

    let userValues = this.form.current.getModifiedValues(false);
    let msg = this.form.current.validateForm();
    if (msg != "") {
      msg = msg.replace(
        "The following required fields are missing:",
        "Please enter a"
      );
      msg = msg.replace(", ", " and ");
      this.displayMessage("Failed Validation", msg);
      this.hitLogin = false;
      return;
    }

    let data = {
      token: this.context.encrypt(
        "username=" +
        userValues.username +
        "&password=" +
        userValues.password
      )
    };
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axiosHttpClient
      .all([
        axiosHttpClient.post(
          this.context.API_URL_HOST +
          DEFAULT_QUERY_LOGIN, 
          data, 
          axiosConfig
        )
      ])
      .then(
        axiosHttpClient.spread((resp) => {
          console.log("err res: ", resp);
          if (resp.data.errorMessage != 0) {
            this.displayMessage("Failed Validation", resp.data.msgError);
            this.hitLogin = false;
          } else if (resp.data.data.errCode != 0) {
            this.displayMessage(
              "Failed Validation",
              resp.data.data.returnMessage
            );
            this.hitLogin = false;
          } else {
            this.redirect_ulr(resp.data, this.context.login_url);
          }
        })
      )
      .catch((error) => {
        console.log(error);
        this.props.displayError(0, "", error);
      });
  }

  //encrypt qs
  redirect_ulr(token, login_url) {
    let qs =
      "token=" +
      token.data.token +
      "&tid=" +
      token.data.clientID +
      "&loginPage=" +
      this.context.encrypt(login_url);
    //redirect to admin or participant for production
    let reactURL = this.context.REACT_URL;
    // if (
    //   reactURL.indexOf("participant.deferral.com") != -1 &&
    //   token.data.groupID == 1
    // ) {
    //   reactURL = reactURL.replace("participant", "admin");
    // }
    window.location.replace(reactURL + "#/dashboard?" + qs);
  }

  render() {
    let isOrix = false; //this.context.custom_client == "orix";
    let isguardian = this.context.custom_client == "guardiannq";
    return (
      <div className="app-body">
        <main className="main">
          <Container>
            {/* <Row className="row-center">Log On</Row> */}
            <Row className="row-center">
              <Col className="col-lg-4 col-md-3 col-2" />
              <Col className="col-lg-4 col-md-6 col-8">
                <Row className="row-padding logon">
                  <Col className="ms-header text-center">
                    {this.props.emailMask != ""
                      ? "Reset your password"
                      : "Log in"}
                  </Col>
                </Row>
                <Row className="row-padding">
                  {this.props.emailMask != "" ? (
                    <Col className="ms-text text-center">
                      Your new password has been sent to the following e-mail
                      address:
                      <br />
                      {this.props.emailMask}.
                    </Col>
                  ) : this.props.action == "logoff" ? (
                    <Col className="ms-text text-center">
                      You have been logged off.
                      <br />
                      To start a new session, simply log in when you're ready.
                    </Col>
                  ) : this.props.action == "sessionclose" ? (
                    <Col className="ms-text text-center">
                      Your session has ended.
                      <br />
                      To start a new session, simply log in when you're ready.
                    </Col>
                  ) : !isguardian ? (
                    <Col className="ms-text text-center">
                      Deferredcomp.morganstanleyatwork.com is now part of the&nbsp;
                      <a
                        href="https://www.morganstanley.com/atwork"
                        target="_blank"
                        className="aMScolor"
                      >
                        Morgan Stanley at Work
                      </a>
                      &nbsp;suite of workplace financial solutions. Please log
                      in to access your benefits.
                    </Col>
                  ) : (
                    <Col className="ms-text text-center">
                      Deferredcomp.morganstanleyatwork.com is now part of the Morgan Stanley at
                      Work&nbsp;suite of workplace financial solutions. Please
                      log in to access your benefits.
                    </Col>
                  )}
                </Row>
              </Col>
              <Col className="col-lg-4 col-md-3 col-2" />
            </Row>
            <Row className="row-center">
              <Col
                className={
                  isOrix ? "col-lg-1 col-md-1 col-2" : "col-lg-4 col-md-3 col-2"
                }
              />
              {/* {isOrix && (
                <React.Fragment>
                  <Col className="col-lg-4 col-md-4 col-8">
                    <Row className="row-nopadding sso-panel mt30">
                      <Col className="text-center">
                        <div className="ms-subheader">Current employees</div>
                        <div className="ms-text mt20">
                          Login via Single Sign-On (SSO) while on your company's
                          VPN or network
                        </div>
                        <Button
                          className="btn btn-secondary ms-logon-btn btn-save-ms "
                          onClick={() => {
                            window.location.href =
                              "https://sso.deferral.com/home/login?EmployerID=974";
                          }}
                        >
                          {"Continue with SSO"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-lg-1 col-md-1 col-2" />
                  <Col className="col-lg-1 col-md-1 col-2">
                    <div className="ms-divider" />
                  </Col>
                </React.Fragment>
              )} */}
              <Col
                className={
                  isOrix ? "col-lg-4 col-md-4 col-8" : "col-lg-4 col-md-6 col-8"
                }
              >
                {/* <Row hidden={!isOrix} className="mt10 row-nopadding">
                  <Col className="text-center ms-subheader">
                    NXT Capital Plan and former employees
                  </Col>
                </Row> */}
                <Row className="mt10">
                  <Col className="text-center">
                    <FormControl
                      ref={this.form}
                      name={"frmUserUpdate"}
                      className1={"deferral-label2"}
                      className2={""}
                      classNameRow1={"row-paddingtop"}
                      classNameRow2={"row-nopadding"}
                      initialStatus={"2"}
                      displayError={this.props.displayError}
                      data={this.UserUpdateData}
                      hideRequiredMark={true}
                      display={[
                        {
                          tag: "RowField2",
                          className: "deferral-field2",
                          field: "username",
                          fieldType: "text",
                          title: "User Name",
                          autoFocus: true,
                          required: true,
                        },
                        {
                          tag: "RowField2",
                          className: "deferral-field2",
                          fieldType: "password",
                          field: "password",
                          title: "Password",
                          required: true,
                          validationRequired: false,
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row className="row-center">
                  <button
                    ref={this.buttonRef}
                    className="btn btn-secondary ms-logon-btn btn-save-ms "
                    hidden={false}
                    data-testid="login"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {"Log in"}
                  </button>
                </Row>
                <Row className="mt20 mb20 ms-text">
                  <Col
                    className="text-center"
                    style={{
                      marginRight:
                        this.props.data == "" ||
                        this.props.data == undefined ||
                        this.context.custom_client.indexOf("login") == 0
                          ? ""
                          : "35px",
                    }}
                  >
                    <span>
                      {this.props.emailMask != "" ? "Still\u00A0" : ""}
                    </span>
                    <a
                      className="aMScolor"
                      href="javascript:void(0)"
                      onClick={() =>
                        this.props.changeMenuIndex("ResetPassword")
                      }
                    >
                      {this.props.emailMask != ""
                        ? "need\u00A0help?"
                        : "Forgot\u00A0Password?"}
                    </a>
                    <span>
                      {this.props.data == "" ||
                      this.props.data == undefined ||
                      this.context.custom_client.indexOf("login") == 0
                        ? ""
                        : "\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0"}
                    </span>
                    <a
                      className="aMScolor"
                      href="javascript:void(0)"
                      onClick={() => this.props.changeMenuIndex("ContactUs")}
                    >
                      {this.props.data == "" ||
                      this.props.data == undefined ||
                      this.context.custom_client.indexOf("login") == 0
                        ? ""
                        : "Contact\u00A0Us"}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col
                className={
                  isOrix ? "col-lg-1 col-md-1 col-2" : "col-lg-4 col-md-3 col-2"
                }
              />
            </Row>
            {this.context.admin_by != "" && (
              <>
                <Row className="mt50">
                  <Col className="text-center">{"Administered By"}</Col>
                </Row>
                <Row className="mb20">
                  <Col className="text-center">
                    <img
                      className="ms-header-logo3"
                      src={
                        "img/private_administered_by/" +
                        this.context.admin_by +
                        "/images/administered_by.png"
                      }
                      alt=""
                    />{" "}
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </main>
      </div>
    );
  }
}

export default LoginForm;
