import React, { useState, useEffect } from "react";
import AppContext from "./AppContext";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "regenerator-runtime/runtime";
import "./web.config";
import { config, cypheriv, cypherkey } from "./constants/api_host";
import { Spinner } from '@morgan-stanley/campfire-client-react';
import { Row, Col } from "reactstrap";

export const DEFAULT_QUERY_contactUs = "Login/GetContact";

import axiosHttpClient from "./constants/axios-http-client";
//STEP 1:
//create components using React.lazy
// const StandardTheme = React.lazy(() =>
//   import("./containers/Full/LayoutStandard.js")
// );
import MSTheme from "./containers/Full/LayoutMS.js";

// Styles - Here
// Import Flag Icons Set
//import "flag-icon-css/css/flag-icon.min.css";

// Import Font Awesome Icons Set
//import 'font-awesome/css/font-awesome.min.css';

// Import Simple Line Icons Set
//import 'simple-line-icons/css/simple-line-icons.css';

// Temp fix for reactstrap
import "./scss/core/_dropdown-menu-right.scss";
// Temp fix for react-datepicker
import "react-datepicker/dist/react-datepicker.min.css";
import queryString from "query-string";

// Import Main styles for this application
//import "../scss/style.scss";
//import "../scss/client67.style.scss";

// Containers
import Full from "./containers/Full/Full";
import SiteDown from "./containers/Full/SiteDown";

// Views
// import Login from "./views/Pages/Login/";
// import Register from "./views/Pages/Register/";
// import Page404 from "./views/Pages/Page404/";
import Page500 from "./views/Pages/Page500/";

//Decrypt Function
var CryptoJS = require("crypto-js");

export function setLocalZone(date) {
  var ref = new Date(date);
  var ret = new Date(ref.getFullYear(), ref.getMonth(), ref.getDate());
  return ret;
}

export function encrypt(text) {
  // if (process.env.NODE_ENV == "production" || true) {
  //if (1 == 1) {
  var key = CryptoJS.enc.Utf8.parse(cypherkey);
  var iv = CryptoJS.enc.Utf8.parse(cypheriv);
  var encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  //console.log(encrypted);
  //console.log(encrypted.toString());
  let b64 = CryptoJS.enc.Base64.parse(encrypted.toString());
  encrypted = b64.toString(CryptoJS.enc.Hex);
  //console.log("encrypt", text);
  //console.log("B64", encrypted);
  return encrypted;
  // } else {
  //   // return text;
  // }
}

/*function decrypt(text) {
  if (process.env.NODE_ENV == "production") {
    //if (1 == 1) {
    var key = CryptoJS.enc.Utf8.parse(cypherkey);
    var iv = CryptoJS.enc.Utf8.parse(cypheriv);
    var textBytes = {
      ciphertext: CryptoJS.enc.Hex.parse(text),
    };
    var bytes = CryptoJS.AES.decrypt(textBytes, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    var decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  } else {
    return text;
  }
}
*/
const userSettingsDefault = {
  setLocalZone: (date) => setLocalZone(date),
  encrypt: (text) => encrypt(text),
  API_URL_HOST: config.API_URL_HOST,
  REACT_URL: config.REACT_URL_HOST,
  API_TOKEN: "",
  custom_client: "",
  admin_by: "",
  login_url: "",
  mainRecordStatus: 0,
};

export function App() {
  const [isLoading, setLoading] = useState(true);
  const [userSettings, setUserSettings] = useState(userSettingsDefault);
  const [isSiteDown, setSiteDown] = useState(false);
  const [action, setAction] = useState("");
  const [contactInfo, setContactInfo] = useState({});

  const setInitialData = () => {
    const redirect = {
      deferral: "www",
      vpn: "www",
      new: "www",
      eplanservices: "eplan",
      afseplanservices: "eplan",
      "clough-chiv": "eplan",
      bpiexecutiveroth: "empirical",
      bpi: "empirical",
      usicg: "nfp",
      chanzuckerberg: "cziservices",
      parrcompanies: "theparrcompany",
      demo: "isopdemo",
      carlsonrezidor: "rhg",
      cci: "ebs",
    };
    const adminBy = {
      afcbrands: "visionlink",
      bemismfg: "visionlink",
      chelseainvestco: "visionlink",
      danainnovations: "visionlink",
      griffincapital: "visionlink",
      isagenixcorp: "visionlink",
    };
    let qs = top.location.search;
    let iPos = qs.indexOf("?");
    if (iPos >= 0) qs = qs.substring(iPos + 1);
    let actionVal = queryString.parse(qs).action;
    setAction(actionVal);
    userSettings.login_url = top.location.href;
    let custom_client = undefined;
    if (queryString.parse(qs).customlogin != undefined) {
      custom_client = queryString.parse(qs).customlogin.toLowerCase();
    }
    if (custom_client === undefined) {
      if (top.location.hostname.indexOf("login-") > 0) {
        custom_client = top.location.hostname
          .substring(
            top.location.hostname.indexOf("-") + 1,
            top.location.hostname.indexOf(".")
          )
          .toLowerCase();
      } else if (top.location.hostname.indexOf(".") != -1) {
        custom_client = top.location.hostname
          .substring(0, top.location.hostname.indexOf("."))
          .toLowerCase();
      } else {
        custom_client = "login";
      }
    }
    // Remove MSNQDCAUTH cookie on page load
    document.cookie = "MSNQDCAUTH=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "MSNQDCAUTH=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.morganstanleyatwork.com;";

    userSettings.custom_client = custom_client;

    if (redirect[custom_client] != undefined) {
      userSettings.custom_client = redirect[custom_client];
    }

    if (adminBy[custom_client] != undefined) {
      userSettings.admin_by = adminBy[custom_client];
    }

    setUserSettings(userSettings);

    /** IMPORTANT: To deploy SiteDown compile with "npm run build_sitedown". To test SiteDown page, run with "npm run sitedown" **/
    const isSiteDown = (process.env.NODE_ENV === undefined);
    setSiteDown(isSiteDown);

    if (!isSiteDown) {
      checkForUserSession();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialData();
  }, []);


  const checkForUserSession = () => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axiosHttpClient.post(userSettings.API_URL_HOST +
      DEFAULT_QUERY_contactUs + "?token=" +
      encrypt("customLogin=" + userSettings.custom_client), '', axiosConfig)
      .then((response) => {
        if (response.data?.config?.ClientId) {
          let ClientId = `${response.data.data.ClientId}`;
          let token = `${response.data.data.TempSessionId}.${response.data.data.UserId}`;
          redirect_ulr(ClientId, token);
        } else {
          setContactInfo(contactInfo);
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
      });
  };

  const redirect_ulr = (ClientId, token) => {
    let qs =
      "token=" +
      token +
      +
      "&tid=" +
      ClientId +
      "&loginPage=" +
      encrypt(userSettings.login_url);
    //redirect to admin or participant for production
    let reactURL = userSettings.REACT_URL;
    window.location.replace(reactURL + "#/dashboard?" + qs);
  };

  return (
    // <React.StrictMode>
    <AppContext.Provider value={userSettings}>
      <MSTheme >
        {isLoading && <Row className="text-center" hidden={!isLoading}>
          <Col>
            <Spinner show={true} size={37} />
          </Col>
        </Row>}
        {!isLoading && <BrowserRouter>
          <Routes>
            {/* <Route path="/React/" element={<Full />} />
        <Route path="/dashboard" element={<Full />} /> */}
            {/* <Route path="/404" element={<Page404 />} /> */}
            <Route path="/500" element={<Page500 />} />

            {
              isSiteDown ?
                <Route path="*" element={<SiteDown />} />
                :
                <Route path="*" element={<Full action={action} contactInfo={contactInfo} />} />
            }
          </Routes>
        </BrowserRouter>}
      </MSTheme>

    </AppContext.Provider>
    //</React.StrictMode>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);



